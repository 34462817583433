import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { faqBlock } from "../../assets/data/datalist";
import Accordion from "../../components/Accordion";
import Footer from "../../components/Footer";
import HeadMenu from "../../components/HeadMenu";
import LoaderIcon from "../../components/LoaderIcon";
import ArticlesRowsSlider from "../../components/slider/ArticlesRowsSlider";
import ArticlesSeoSlider from "../../components/slider/ArticlesSeoSlider";
import { ServiceSectionProvider } from "../../context/ServiceSectionContext";
import useFetchPosts from "../../services/hooks/useFetchPosts";
import { useMediaQuery } from "../../services/hooks/useMediaQuery";
import { useScrollPosition } from "../../services/utils";

export default function CategoryPage({
  title,
  slug,
  categoryTitle,
}: {
  title?: string;
  slug?: string;
  categoryTitle?: string;
}) {
  const navigate = useNavigate();

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }
  const [showConversationModal, setShowConversationModal] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const scrollPosition = useScrollPosition();
  const postData = useFetchPosts(
    `${process.env.STRAPI_API_URL}/api/articles?pagination[pageSize]=100&populate=*`,
    `${process.env.STRAPI_API_URL}/api/secondary-articles?pagination[pageSize]=100&populate=*`,
    `${process.env.STRAPI_API_URL}/api/article-categories?pagination[pageSize]=100&populate=*`
  );

  const parentCategories = postData.categoriesList?.data
    ?.filter(
      (item: any) => !item?.attributes.parent?.data.some((it: any) => it)
    )
    ?.sort((a: any, b: any) =>
      a.attributes.slug > b.attributes.slug
        ? 1
        : b.attributes.slug > a.attributes.slug
        ? -1
        : 0
    );

  const childCategories = postData?.categoriesList?.data
    ?.filter(
      (item: any) =>
        item?.attributes.parent?.data.some((it: any) => it) &&
        item?.attributes.parent?.data[0]?.attributes.slug === slug
    )
    ?.sort((a: any, b: any) =>
      a.attributes.slug > b.attributes.slug
        ? 1
        : b.attributes.slug > a.attributes.slug
        ? -1
        : 0
    );

  let filteredPilarPosts = postData?.posts?.data.filter(
    (x: any) => x.attributes?.article_category.data.attributes.slug === slug
  );
  for (let i = 0; i < postData.categoriesList?.data?.length; i++) {
    const articles = postData?.posts?.data.filter(
      (x: any) =>
        x.attributes.article_category.data.attributes.slug ===
        childCategories[i]?.attributes?.slug
    );

    filteredPilarPosts = filteredPilarPosts.concat(...articles);
  }

  let filteredPosts = [];
  for (let i = 0; i < filteredPilarPosts?.length; i++) {
    const articles = postData.postsSecondary.data?.filter(
      (x: any) =>
        x.attributes.pilar_article.data?.attributes.slug ===
        filteredPilarPosts[i].attributes.slug
    );

    filteredPosts = filteredPosts.concat(...articles);
  }

  // console.log(filteredPilarPosts);
  // console.log(articles);

  useEffect(() => {
    document.title = title!;
  }, [title]);

  const handleChange = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const selectedCategory = e.target.value;

    selectedCategory === "all"
      ? navigate(`/artiklar/`)
      : navigate(`/artiklar/${selectedCategory}/`);
  };

  useEffect(() => {
    if (showConversationModal) {
      setShowConversationModal(false);
    }
  }, [showConversationModal]);
  return (
    <ServiceSectionProvider>
      <div className="relative overflow-hidden bg-[#FFFEFB]">
        <div
          className={classNames(
            scrollPosition > 84 ? "bg-white" : "bg-white",
            "fixed z-30 w-full transition-colors"
          )}
        >
          <HeadMenu
            sticky={scrollPosition > 84}
            theme={"dark"}
            color={scrollPosition > 84 ? "#0C2632" : "#0C2632"}
          />
        </div>
        <div className="relative w-full pb-28 text-deep-blue lg:pb-36">
          <div className="mx-auto px-5 pt-24 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] lg:pt-24 xl:max-w-[1360px]">
            <div className="hero-guidance max-w-[980px]">
              <ul className="space-x-2 text-[11px] capitalize">
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/"}>hem</Link>
                </li>
                <li className="inline after:ml-2.5 after:content-['|']">
                  <Link to={"/artiklar/"}>artiklar</Link>
                </li>
                <li className="pointer-events-none inline">
                  <Link to={`/artiklar/${slug}/`}>{categoryTitle}</Link>
                </li>
              </ul>
              <h1 className="mt-20 block font-interblack text-[36px] italic leading-[2.7rem] xl:mt-9 xl:text-[64px] xl:leading-[4.4rem]">
                Lev livet smart utan funderingar
              </h1>
            </div>
            <div className="pt-6 lg:pt-9">
              <p className="font-inter mb-3 border-t border-[#CECABA] pt-6 text-[11px] tracking-widest text-[#736F62]">
                Fenix Family artiklar sorterade efter kategorier:
              </p>
              {isDesktop ? (
                <div className="filter-buttons no-scrollbar -mr-12 flex space-x-5 overflow-x-auto">
                  {parentCategories && (
                    <Link
                      to={"/artiklar/"}
                      className="flex h-[44px] items-center justify-center whitespace-nowrap rounded-[40px] px-4 text-sm uppercase tracking-widest text-deep-blue"
                    >
                      Alla
                    </Link>
                  )}
                  {parentCategories?.map((item: any, index: number) => {
                    return (
                      <Link
                        key={index}
                        to={`/artiklar/${item.attributes.slug}/`}
                        className={
                          slug === item.attributes.slug
                            ? "flex h-[44px] items-center justify-center whitespace-nowrap rounded-[40px] bg-deep-blue px-4 text-sm uppercase tracking-widest text-white"
                            : "flex h-[44px] items-center justify-center whitespace-nowrap rounded-[40px] px-4 text-sm uppercase tracking-widest text-deep-blue"
                        }
                      >
                        {item.attributes.title}
                      </Link>
                    );
                  })}
                </div>
              ) : (
                <div className="relative flex items-center after:absolute after:top-1/3 after:right-4 after:h-[10px] after:w-[10px] after:rotate-45 after:transform after:border-b after:border-r after:border-black/70">
                  <select
                    value={slug}
                    onChange={handleChange}
                    id="filter-select"
                    className="h-[44px] w-full appearance-none rounded border border-[#CECABA] px-3 font-internormal font-[13px]"
                  >
                    <option value="all">Alla</option>
                    {parentCategories?.map((item: any, index: number) => {
                      return (
                        <option
                          key={index}
                          value={item.attributes.slug}
                          className="text-base uppercase tracking-widest text-deep-blue"
                        >
                          {item.attributes.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              <div className="filter-results mt-20">
                <ArticlesRowsSlider
                  articles={filteredPilarPosts}
                  childCategories={childCategories}
                />
              </div>
            </div>
          </div>
          {filteredPosts && filteredPosts.length > 0 ? (
            <div className="bg-neutral-yellow">
              <div className="relative mx-auto flex max-w-[1000px] px-5 pt-12 pb-20">
                <ArticlesSeoSlider
                  articlesSecondary={filteredPosts}
                  pilarArticles={filteredPilarPosts}
                  childCategories={childCategories}
                />
              </div>
            </div>
          ) : postData.loading === true ? (
            <LoaderIcon />
          ) : (
            ""
          )}
          <div className="mx-auto px-5 pt-32 sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1360px]">
            <div className="mx-auto mt-3 max-w-[870px]">
              <h2 className="mx-auto mb-5 max-w-[210px] text-center font-interblack text-[28px] italic leading-tight lg:mb-10 lg:max-w-full lg:text-5xl">
                FAQ inom vald kategori
              </h2>
              <Accordion items={faqBlock} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </ServiceSectionProvider>
  );
}
