export type PartnerType = {
  title: string;
  pageTitle: string;
  url: string;
  pkg?: number;
  price?: string;
  fee?: string;
  hero?: URL;
  logo?: URL;
  logo2?: URL;
  tagline?: string;
  contact?: boolean;
};

export const defaultPackagePrice = "5999";

export const partners: PartnerType[] = [
  {
    title: "Bostadsrätterna",
    pageTitle: "Bostadsrätterna - Fenix",
    url: "bostadsratterna",
    logo: new URL(
      "../assets/images/bostadsratterna_logotyp_cmyk.png",
      import.meta.url
    ),
    pkg: process.env.NODE_ENV === "production" ? 107 : 119,
    price: "0",
    fee: "0",
  },
  {
    title: "SocialMedia",
    pageTitle: "Fenix",
    url: "social-media",
  },
  {
    title: "Fenix society",
    pageTitle: "Fenix",
    url: "fenix-society",
  },
  {
    title: "Fenix family",
    pageTitle: "Fenix",
    url: "fenixfamily",
    pkg: 99,
    price: "10",
  },
  {
    title: "ActiveCampaign",
    pageTitle: "Fenix",
    url: "active-campaign",
    pkg: 101,
    price: "1499",
  },
  {
    title: "Novu",
    pageTitle: "Novu - Fenix",
    url: "novu",
    logo: new URL("../assets/images/novu.png", import.meta.url),
    price: "0",
    pkg: 4,
  },
  {
    title: "SKFS",
    pageTitle: "SKFS - Fenix",
    url: "skfs",
    logo: new URL("../assets/images/skfs24.png", import.meta.url),
    pkg: 5,
    price: "0",
    tagline:
      "All livsplanering du behöver kostnadsfritt för SKFS Service medlemmar (Ordinarie pris 5999:-)",
  },
  {
    title: "Svensk Fond och Försäkring 695",
    pageTitle: "Svensk Fond och Försäkring - Fenix",
    url: "svenskfond",
    pkg: 6,
    price: "695",
  },
  {
    title: "Strategipension",
    pageTitle: "Strategipension - Fenix",
    url: "strategipension",
    logo: new URL("../assets/images/strategipension.png", import.meta.url),
    pkg: 7,
    price: "5499",
    fee: "449",
  },
  {
    title: "Tydliga",
    pageTitle: "Tydliga Mats Julle - Fenix",
    url: "tydliga",
    logo: new URL("../assets/images/tydliga_partner.jpg", import.meta.url),
    pkg: 8,
    price: "0",
  },
  {
    title: "Tydliga",
    pageTitle: "Tydliga Mats Julle - Fenix",
    url: "tydliga2",
    logo: new URL("../assets/images/tydliga_partner.jpg", import.meta.url),
    pkg: 136,
    price: "5999",
  },
  {
    title: "Tydliga Partners Västerbotten/Erad Fond & Försäkring",
    pageTitle: "Tydliga Partners Västerbotten/Erad Fond & Försäkring - Fenix",
    url: "tydligapartners1",
    logo: new URL("../assets/images/tydliga_partner.jpg", import.meta.url),
    pkg: 113,
    price: "0",
  },
  {
    title: "Tydliga Partners/Pension & Framtid i Halland AB",
    pageTitle: "Tydliga Partners/Pension & Framtid i Halland AB - Fenix",
    url: "tydligapartners2",
    logo: new URL("../assets/images/tydliga_partner.jpg", import.meta.url),
    pkg: 114,
    price: "0",
  },
  {
    title: "Tydliga Partners/Jens Carlquist",
    pageTitle: "Tydliga Partners/Jens Carlquist - Fenix",
    url: "tydligapartners3",
    logo: new URL("../assets/images/tydliga_partner.jpg", import.meta.url),
    pkg: 116,
    price: "3499",
    fee: "474",
  },
  {
    title: "Joakim Lundbladh",
    pageTitle: "Joakim Lundbladh - Fenix",
    url: "malardalens",
    logo: new URL("../assets/images/malardalens_logo.svg", import.meta.url),
    pkg: 130,
    price: "5999",
  },
  {
    title: "Transactor AB",
    pageTitle: "Transactor AB - Fenix",
    url: "transactor",
    pkg: 131,
    price: "5999",
  },
  {
    title: "Mind Sverige AB",
    pageTitle: "Mind Sverige AB - Fenix",
    url: "mind",
    logo: new URL("../assets/images/mind_logo.jpg", import.meta.url),
    pkg: 132,
    price: "4999",
    fee: "499",
  },
  {
    title: "Mäklarhuset",
    pageTitle: "Mäklarhuset - Fenix",
    url: "maklarhuset1",
    logo: new URL("../assets/images/MH_Logotyp_svart.jpg", import.meta.url),
    pkg: 122,
    price: "0",
  },
  {
    title: "Claudiu",
    pageTitle: "Fenix",
    url: "claudiu",
    pkg: 9,
    price: "0",
  },
  {
    title: "SGJ Capital",
    pageTitle: "SGJ Capital - Fenix",
    url: "sgjcapital",
    logo: new URL("../assets/images/sgjcapital.jpg", import.meta.url),
    pkg: 105,
    price: "0",
  },
  {
    title: "Mybenefit",
    pageTitle: "Mybenefit - Fenix",
    url: "mybenefit",
    pkg: process.env.NODE_ENV === "production" ? 119 : 127,
    price: "5100",
  },
  {
    title: "Pensionsinformation",
    pageTitle: "Pensionsinformation - Fenix",
    url: "pensionsinformation",
    logo: new URL(
      "../assets/images/logga-pensionsinformation.jpg",
      import.meta.url
    ),
    pkg: process.env.NODE_ENV === "production" ? 121 : 128,
    price: "5999",
  },
  {
    title: "Pensionsinformation",
    pageTitle: "Pensionsinformation - Fenix",
    url: "pensionsinformation2",
    logo: new URL(
      "../assets/images/logga-pensionsinformation.jpg",
      import.meta.url
    ),
    pkg: 132,
    price: "7999",
    fee: "1198",
  },
  {
    title: "Pensionsinformation",
    pageTitle: "Pensionsinformation - Fenix",
    url: "pensionsinformation3",
    logo: new URL(
      "../assets/images/logga-pensionsinformation.jpg",
      import.meta.url
    ),
    pkg: 126,
    price: "0",
  },
  {
    title: "Svensk Hypotekspension",
    pageTitle: "Svensk Hypotekspension - Fenix",
    url: "svenskhypotekspension",
    hero: new URL(
      "../assets/images/230815_Nordax_16_0130.jpg",
      import.meta.url
    ),
    logo: new URL("../assets/images/shp-logo-2-rader.png", import.meta.url),
    logo2: new URL("../assets/images/shp-logo-1-rad.jpg", import.meta.url),
    pkg: 112,
    price: "0",
  },
  {
    title: "Lindhe & Partners",
    pageTitle: "Lindhe & Partners - Fenix",
    url: "lindhe",
    logo: new URL("../assets/images/lindhe.jpg", import.meta.url),
    pkg: 106,
    price: "0",
  },
  {
    title: "Lindhe & Partners",
    pageTitle: "Lindhe & Partners - Fenix",
    url: "lindhe2",
    logo: new URL("../assets/images/lindhe.jpg", import.meta.url),
    pkg: 137,
    price: "1995",
  },
  {
    title: "Mandat Kapitalförvaltning AB",
    pageTitle: "Mandat - Fenix",
    url: "mandat",
    logo: new URL("../assets/images/mandat.jpg", import.meta.url),
    pkg: 104,
    price: "0",
  },
  {
    title: "Försäkringsakademien",
    pageTitle: "Försäkringsakademien - Fenix",
    url: "forsakringsakademien",
    logo: new URL("../assets/images/FA_logo.png", import.meta.url),
    pkg: 118,
    price: "5999",
    tagline: "Livsplanering för nyckelkunder",
    contact: true,
  },
  {
    title: "Försäkringsakademien",
    pageTitle: "Försäkringsakademien - Fenix",
    url: "forsakringsakademien1",
    logo: new URL("../assets/images/FA_logo.png", import.meta.url),
    pkg: 124,
    price: "0",
  },
  {
    title: "Equity",
    pageTitle: "Equity - Fenix",
    url: "equity",
    logo: new URL("../assets/images/equity_logo.svg", import.meta.url),
    pkg: 120,
    price: "2995",
  },
  {
    title: "Zikta Invest AB",
    pageTitle: "Zikta Invest AB - Fenix",
    url: "zikta",
    logo: new URL("../assets/images/zikta-logo.svg", import.meta.url),
    pkg: 133,
    price: "0",
  },
  {
    title: "Falk & Partners",
    pageTitle: "Falk & Partners - Fenix",
    url: "falk",
    logo: new URL(
      "../assets/images/logo_falk_och_partners.svg",
      import.meta.url
    ),
    pkg: 134,
    price: "0",
  },
  {
    title: "Ewiga AB",
    pageTitle: "Ewiga AB - Fenix",
    url: "ewiga2",
    logo: new URL("../assets/images/logo-Ewiga.png", import.meta.url),
    pkg: 135,
    price: "5999",
  },
  {
    title: "Fenix Family Partner Society",
    pageTitle: "Fenix Family Partner Society",
    url: "society",
    pkg: 138,
    price: "5999",
  },
  {
    title: "Fenix Family Partner Society 1",
    pageTitle: "Fenix Family Partner Society",
    url: "society1",
    pkg: 141,
    price: "5499",
  },
  {
    title: "Tydliga Partners/Pension & Framtid i Halland AB",
    pageTitle: "Tydliga Partners/Pension & Framtid i Halland AB - Fenix",
    url: "tydliga-pension-framtid-halland",
    logo: new URL("../assets/images/tydliga_partner.jpg", import.meta.url),
    pkg: 139,
    price: "3499",
    fee: "474",
  },
  {
    title: "TCFM",
    pageTitle: "TCFM - Fenix",
    url: "tcfm",
    // logo: new URL("../assets/images/logo-Ewiga.png", import.meta.url),
    pkg: 140,
    price: "5499",
  },
  {
    title: "Aktiv Liv och Finans",
    pageTitle: "Aktiv Liv och Finans - Fenix",
    url: "alf",
    logo: new URL("../assets/images/alf.jpg", import.meta.url),
    pkg: 109,
    price: "0",
  },
  {
    title: "Fenix",
    pageTitle: "Fenix",
    url: "fenix",
    pkg: process.env.NODE_ENV === "production" ? 110 : 120,
    price: "5999",
  },
  {
    title: "Fenix",
    pageTitle: "Fenix",
    url: "fenix-3",
    pkg: 111,
    price: "0",
  },
  {
    title: "SPF Seniorerna",
    pageTitle: "SPF Seniorerna - Fenix",
    url: "spf-seniorerna",
    logo: new URL("../assets/images/spf_logo.svg", import.meta.url),
    pkg: process.env.NODE_ENV === "production" ? 155 : 153,
    price: "0",
    fee: "0",
  },
  {
    title: "Dinfond",
    pageTitle: "Dinfond - Fenix",
    url: "dinfond",
    logo: new URL("../assets/images/hjerta.png", import.meta.url),
    pkg: process.env.NODE_ENV === "production" ? 145 : 145,
    price: "5999",
    fee: "599",
  },
  {
    title: "Humana",
    pageTitle: "Humana - Fenix",
    url: "humana",
    logo: new URL("../assets/images/humana-logotype.svg", import.meta.url),
    pkg: process.env.NODE_ENV === "production" ? 151 : 149,
    price: "0",
    fee: "0"
  },
  {
    title: "Vardaga",
    pageTitle: "Vardaga - Fenix",
    url: "vardaga",
    pkg: process.env.NODE_ENV === "production" ? 152 : 150,
    price: "0",
    fee: "0"
  },
  {
    title: "Dalarna",
    pageTitle: "Dalarna - Fenix",
    url: "dalarna",
    pkg: process.env.NODE_ENV === "production" ? 153 : 151,
    price: "0",
    fee: "0"
  },
  {
    title: "Convendum",
    pageTitle: "Convendum - Fenix",
    url: "convendum",
    logo: new URL("../assets/images/convendum_square_c_logo.png", import.meta.url),
    pkg: process.env.NODE_ENV === "production" ? 154 : 152,
    price: "0",
    fee: "0"
  },
];

export function getPartnerByUrl(url: string | null) {
  if (url === null) {
    return null;
  }

  const partnerExist = partners.find((p) => url === p.url);
  return partnerExist ? partnerExist : null;
}
